<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="图片：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="展示店铺：" prop="shopId">
            <el-select clearable filterable v-model="form.shopId" placeholder="请选择">
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否显现：" prop="switchFlag">
            <el-radio-group v-model="form.switchFlag">
              <el-radio :label=1>是</el-radio>
              <el-radio :label=0>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="链接类型：" prop="linktype">
            <el-select v-model="form.linktype" placeholder="请选择">
              <el-option
                v-for="(item, index) in ADVERT_LINK_TYPE_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小程序AppId：" prop="appId">
            <el-input v-model="form.appId"></el-input>
          </el-form-item>
          <el-form-item label="页面路径：" prop="pageurl">
            <el-input v-model="form.pageurl"></el-input>
          </el-form-item>
          <el-form-item label="图片位置：" prop="location">
            <el-radio-group v-model="form.location">
              <el-radio label="top">顶部轮播图</el-radio>
              <el-radio label="popup">中部弹出图</el-radio>
              <el-radio label="bottom">底部推荐图</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="权重：" prop="weigh">
            <el-input v-model="form.weigh" type="number"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ADVERT_LINK_TYPE_OPTIONS } from "@/helpers/constants";
import { getShopOptions } from "@/apis/unidrink/shop";
import { addAdvert, editAdvert } from "@/apis/unidrink/advert";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      ADVERT_LINK_TYPE_OPTIONS: ADVERT_LINK_TYPE_OPTIONS,
      initForm: {
        linktype: "pages",
        switchFlag: 1,
        shopId: "",
        location: "top",
        weigh:0
      },
      shopOptions:[],
      form: {},
      rules: {
        shopId: [{ required: true, message: "展示店铺不能为空", trigger: "blur" }],
        weigh: [{ required: true, message: "权重不能为空", trigger: "blur" }],
      },
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        getShopOptions(this.shopOptions);
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const promise = this.mode === "edit" ? editAdvert : addAdvert;
        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
